<template>
  <v-container id="error-view" class="fill-height text-center" tag="section">
    <v-row justify="center">
      <v-col cols="auto">
        <h1 class="text-h2 mb-16 font-weight-bold">{{status}}</h1>

        <div class="text-h4 pt-8 pb-10">{{message}}</div>

        <v-btn depressed large to="/">Get me out of here! </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ErrorView',
    data: () => ({
    status: null,
    message: null,
  }),
  beforeMount() {
    this.status = this.$route.query.status
    if (this.status == 500) {
      this.message = "Internal server error"
    } else if (this.status == 409) {
      this.message = "Conflict. Please try again"
    } else if (this.status == 404) {
      this.message = "Page not found"
    } else {
      this.message = `Status = ${this.status}`
    }
  },
  }
</script>

<style lang="sass">
#error-view h1.text-h1
  font-size: 14rem !important
</style>
